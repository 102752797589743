<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <!--  Edit Lead -->

  <v-card>
    <v-card-title primary-title> Edit Lead </v-card-title>

    <v-card-text class="mt-3">
      <v-overlay absolute :value="loadingAcciones">
        <v-progress-circular indeterminate size="34"></v-progress-circular>
      </v-overlay>
      <v-form ref="registerForm" v-model="formValid">
        <v-container>
          <div class="field">
            <v-text-field
              rounded
              :rules="[rules.required]"
              v-model="itemEdit.name"
              label="Name"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="field">
            <v-text-field
              rounded
              :rules="[rules.required]"
              v-model="itemEdit.last_name"
              label="Last Name"
              outlined
              dense
            ></v-text-field>
          </div>
          <v-row class="field">
            <v-col cols="12" sm="3">
              <v-text-field
                rounded
                prepend-inner-icon="mdi-numeric-positive-1"
                label="Phone *"
                :rules="[rules.phone]"
                v-model="itemEdit.phone"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                rounded
                :rules="[rules.email, rules.required]"
                v-model="itemEdit.email"
                label="Email"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                v-model="itemEdit.statusNom"
                :items="status"
                item-text="name"
                item-value="id"
                label="Status"
                outlined
                dense
              />
            </v-col>

            <v-col cols="12" sm="3">
              <v-radio-group v-model="itemEdit.isMale" row>
                <v-radio label="Female" :value="false"></v-radio>
                <v-radio label="Male" :value="true"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="field mt-1" justify="center">
            <v-col cols="12" sm="3">
              <ma-date-picker
                v-model="itemEdit.birthDate"
                past
                label="Date of Birth"
                :editable="true"
            /></v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                label="Weight (lbs)"
                v-model="itemEdit.weight"
                outlined
                dense
              ></v-text-field
            ></v-col>

            <v-col
              class="d-flex flex-column align-center heightfield"
              cols="12"
              sm="6"
            >
              <label
                >Height:
                <strong>
                  {{ heightfeet + "'." + heightinch + '"' }}</strong
                ></label
              >
              <div class="d-flex mt-1">
                <v-text-field
                  class="mx-1"
                  label="Feet"
                  v-model="heightfeet"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  class="mx-1"
                  label="Inch"
                  v-model="heightinch"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <template v-if="formComposition.doctorChoise.active">
              <v-col cols="12" sm="6">
                <div class="field">
                  <v-autocomplete
                    :label="formComposition.doctorChoise.label"
                    outlined
                    v-model="itemEdit.medicalHistory.doctorChoiceUuid"
                    rounded
                    :items="doctors"
                    item-text="fullname"
                    item-value="uuid"
                    dense
                  ></v-autocomplete>
                </div>
              </v-col>
            </template>

            <template v-if="formComposition.procedureInterest.active">
              <v-col cols="12" sm="6">
                <div class="field">
                  <v-autocomplete
                    :label="formComposition.procedureInterest.label"
                    outlined
                    v-model="itemEdit.procedureInterest"
                    rounded
                    :items="types"
                    item-text="name"
                    item-value="name"
                    dense
                  ></v-autocomplete>
                </div>
              </v-col>
            </template>
          </v-row>

          <template v-if="formComposition.medicalCondition.active">
            <div class="field">
              <v-text-field
                :label="formComposition.medicalCondition.label"
                outlined
                v-model="itemEdit.medicalHistory.medicalCondition"
                rounded
                dense
              ></v-text-field>
            </div>
          </template>
          <template v-if="formComposition.medications.active">
            <div class="field">
              <v-text-field
                :label="formComposition.medications.label"
                outlined
                v-model="itemEdit.medicalHistory.currentMedications"
                rounded
                dense
              ></v-text-field>
            </div>
          </template>
          <template v-if="formComposition.alergies.active">
            <div class="field">
              <v-textarea
                :label="formComposition.alergies.label"
                outlined
                v-model="itemEdit.medicalHistory.alergies"
                auto-grow
                rows="1"
                row-height="45"
              ></v-textarea>
            </div>
          </template>
          <template v-if="formComposition.bloodClots.active">
            <div class="field">
              <label>{{ formComposition.bloodClots.label }}</label>
              <v-textarea
                outlined
                v-model="itemEdit.medicalHistory.bloodClotsOrClotting"
                auto-grow
                rows="1"
                row-height="65"
                :label="formComposition.bloodClots.label2"
                value=""
              ></v-textarea>
            </div>
          </template>
          <template v-if="formComposition.previousSurgery.active">
            <div class="field">
              <label for="">{{ formComposition.previousSurgery.label }}</label>
              <v-radio-group v-model="previousProc" row>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
              <template
                v-if="previousProc && formComposition.previousSurgery.active"
              >
                <v-text-field
                  outlined
                  rounded
                  dense
                  v-model="itemEdit.medicalHistory.previosSurgery"
                >
                  <template slot="label">
                    <span>{{ formComposition.previousSurgery.label2 }}</span>
                  </template>
                </v-text-field>
              </template>
            </div>
          </template>
          <div class="field d-flex justify-space-between">
            <template v-if="formComposition.buttshots.active">
              <v-switch
                inset
                :label="formComposition.buttshots.label"
                v-model="itemEdit.medicalHistory.buttShotsInjections"
              ></v-switch>
            </template>
            <template v-if="formComposition.children.active">
              <v-switch
                inset
                :label="formComposition.children.label"
                v-model="itemEdit.children"
              ></v-switch>
            </template>
          </div>
          <div class="field d-flex justify-space-between">
            <template v-if="formComposition.smoke.active">
              <v-switch
                inset
                :label="formComposition.smoke.label"
                v-model="itemEdit.medicalHistory.smoking"
              ></v-switch>
              <v-spacer></v-spacer>
            </template>

            <template v-if="formComposition.alcohol.active">
              <v-switch
                inset
                :label="formComposition.alcohol.label"
                v-model="itemEdit.medicalHistory.alcohol"
              ></v-switch>
            </template>
          </div>
          <div class="field d-flex justify-space-between">
            <template v-if="formComposition.other.active">
              <v-switch
                inset
                :label="formComposition.other.label"
                v-model="othersubstances"
              ></v-switch>
              <v-spacer></v-spacer>
              <template v-if="othersubstances">
                <v-textarea
                  outlined
                  auto-grow
                  rows="1"
                  row-height="65"
                  :label="formComposition.other.label2"
                  v-model="itemEdit.medicalHistory.otherSubstances"
                ></v-textarea>
              </template>
            </template>
          </div>
          <v-row class="field">
            <template v-if="formComposition.desireSurgeryDate.active">
              <v-col cols="12" sm="6">
                <v-select
                  clearable
                  :items="desireSurgery"
                  item-value="value"
                  v-model="itemEdit.medicalHistory.desiredSurgeryDate"
                >
                  <template slot="label">
                    <p class="labels">
                      {{ formComposition.desireSurgeryDate.label }}
                    </p>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip small dark :class="item.color">
                      {{ item.label }}
                    </v-chip>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip small dark :class="item.color">
                      {{ item.label }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </template>
            <template v-if="formComposition.source.active">
              <v-col cols="12" sm="6">
                <v-select
                  :loading="loadingRefer"
                  :items="references"
                  item-text="name"
                  item-value="name"
                  v-model="itemEdit.howKnow"
                >
                  <template slot="label">
                    <p class="labels1">{{ formComposition.source.label }}</p>
                  </template>
                </v-select></v-col
              >
            </template>
          </v-row>
          <div class="field d-flex justify-space-between">
            <template v-if="formComposition.details.active">
              <div class="col-sm-6 notes">
                <v-textarea
                  outlined
                  auto-grow
                  rows="1"
                  row-height="65"
                  :label="formComposition.details.label"
                  v-model="itemEdit.medicalHistory.details"
                ></v-textarea>
              </div>
            </template>
          </div>
          <template v-if="formComposition.besttime.active">
            <div class="field">
              <label for="">{{ formComposition.besttime.label }}</label>
              <p class="text--secondary">
                {{ formComposition.besttime.label2 }}
              </p>
              <v-text-field
                v-model="itemEdit.medicalHistory.bestTimeToCall"
                rounded
                outlined
                dense
              ></v-text-field>
            </div>
          </template>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="actions">
      <v-spacer></v-spacer>
      <v-btn text @click="cancelEdit" color="error">Cancel</v-btn>
      <v-btn
        text
        :disabled="!formValid"
        :loading="loadingAcciones"
        @click="confirmEdit"
        color="primary"
        >Edit</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import rules from "@/components/account/rules";
import { mapState, mapActions } from "vuex";
import { getAPI } from "@/api/axios-base";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { desireSurgery } from "@/utils/consts";
import formatPhone from "@/utils/formatPhone";
import { notifyError, notifySuccess } from "@/components/Notification";
import moment from "moment";
export default {
  name: "edit-lead",
  components: { MaDatePicker },

  data() {
    return {
      item: null,

      desireSurgery,
      editDOB: false,
      loadingAcciones: false,
      gender: "female",
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
        maxCharsfull: (v) => v.length >= 3 || this.$t("min3Chars"),
      },
      types: [],
      status: [],
      formValid: false,
      loadingProcedures: false,

      othersubstances: false,
      typeProcedure: "Surgery",

      dialogEdit: false,
      heightinch: 0,
      heightfeet: 0,
      previousProc: false,
      itemEdit: {
        leadUuid: null,
        statusNom: null,
        howKnow: null,
        name: null,
        last_name: null,
        phone: null,
        email: null,
        weight: 0,
        height: 0,
        isMale: false,
        birthDate: null,
        children: false,
        procedureInterest: null,
        medicalHistory: {
          doctorChoiceUuid: null,
          smoking: false,
          alcohol: false,
          buttShotsInjections: false,
          otherSubstances: null,
          medicalCondition: null,
          alergies: null,
          previosSurgery: null,
          currentMedications: null,
          details: null,
          bloodClotsOrClotting: null,
          bestTimeToCall: null,
          desiredSurgeryDate: null,
        },
      },

      formComposition: {
        sourceDefault: null,
        doctorChoise: {
          name: "doctorChoise",
          label: "Doctor of Choice",
          active: true,
        },
        procedureInterest: {
          name: "procedureInterest",
          label: "Procedure of Interest",
          active: true,
        },
        medicalCondition: {
          name: "medicalCondition",
          label: "Do you have any medical conditions?",
          active: true,
        },
        medications: {
          name: "medications",
          label: "Do you take any medications?",
          active: true,
        },
        alergies: {
          name: "alergies",
          label: "Alergies",
          active: true,
        },
        bloodClots: {
          name: "bloodClots",
          label: "Have you ever had any blood clots/ blood clotting?",
          label2: "If yes, please describe the situation and when it happened",
          active: true,
        },
        previousSurgery: {
          name: "previousSurgery",
          label: "Any procedures or surgeries in the last 3 months?",
          label2: "Please specify.",
          active: true,
        },
        buttshots: {
          name: "buttshots",
          label: "Do you have butt shots/ injections?",
          active: true,
        },
        children: {
          name: "children",
          label: "Do you have Children?",
          active: true,
        },
        smoke: {
          name: "smoke",
          label: "Do you smoke or use any nicotine products?",
          active: true,
        },
        alcohol: {
          name: "alcohol",
          label: "Do you drink alcohol?",
          active: true,
        },
        other: {
          name: "other",
          label: "Other Substances",
          label2: "Substances",
          active: true,
        },
        desireSurgeryDate: {
          name: "desireSurgeryDate",
          label: "Desired Surgery Date",
          active: true,
        },
        source: {
          name: "source",
          label: "Please tell us how you heard about us",
          active: true,
        },
        details: {
          name: "details",
          label: "Notes",
          active: true,
        },
        photo: {
          name: "photo",
          label: "Photos",
          label1: "Attach files",
          active: true,
        },
        besttime: {
          name: "besttime",
          label: "Best time to call",
          label1: "(office hours 9am- 6pm, M-F)",
          active: true,
        },
        emailme: {
          name: "emailme",
          label: "Email me a copy of my responses.",
          label1:
            "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
          active: true,
        },
      },
    };
  },

  async mounted() {
    await this.loadProcedures();
    await this.loadLeadStatus();
    await this.actListReferences();
    await this.actGetPatientDoctors();
    this.getLeadData();
    localStorage.setItem("leadcenter", true);
  },
  computed: {
    ...mapState(["configForm", "defaultFormId"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState("crmEmployeeModule", ["coordinators", "doctors", "loading"]),
    dateSend() {
      if (
        this.itemEdit.birthDate == null ||
        this.itemEdit.birthDate == undefined
      ) {
        return "";
      }
      const f = moment(this.itemEdit.birthDate).format("YYYY-DD-MM");
      return f;
    },

    dobShow() {
      if (
        this.itemEdit.birthDate == null ||
        this.itemEdit.birthDate == undefined
      ) {
        return "";
      }
      const f = moment(this.itemEdit.birthDate).format("MM-DD-YYYY");
      return f;
    },
  },

  methods: {
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    ...mapActions("crmEmployeeModule", ["actGetPatientDoctors"]),
    getLeadData() {
      this.loadingAcciones = true;
      const leaduuid = this.$route.params.uuid;
      getAPI("/social/getLead/" + leaduuid)
        .then((res) => {
          this.item = res.data;
          this.loadingAcciones = false;
          this.init();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAcciones = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelEdit() {
      const leaduuid = this.$route.params.uuid;
      if (localStorage.getItem("from") == "lead") {
        this.$router.push(`/leads_details/${leaduuid}`);
      } else {
        this.$router.push("/leads_center");
      }
    },

    confirmEdit() {
      this.loadingAcciones = true;
      const body = Object.assign({}, this.itemEdit);
      body.phone = "+1" + body.phone;

      body.weight =
        body.weight != null && body.weight != "" ? Number(body.weight) : 0;
      body.height = Number(this.heightfeet + "." + this.heightinch);

      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifySuccess("Lead has been Edited");
          this.loadingAcciones = false;
          this.cancelEdit();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    loadProcedures() {
      getAPI("/procedure-form-nom/list").then((res) => {
        this.types = res.data;
      });
    },
    init() {
      this.initForm();

      if (this.item) {
        let pho = this.item.phone;
        if (pho.includes("+1")) {
          pho = pho.replace("+1", "");
        }
        pho = pho.replaceAll("\t", "");
        pho = pho.replaceAll(" ", "");
        pho = formatPhone(pho);
        if (
          this.item.medicalHistory != null &&
          this.item.medicalHistory.otherSubstances
        ) {
          this.othersubstances = true;
        }

        if (this.item.height) {
          const st = this.item.height.toString();
          this.heightfeet = Number(st.split(".")[0]);
          this.heightinch = Number(st.split(".")[1]);
        }

        this.itemEdit = {
          leadUuid: this.item.uuid,
          name: this.item.name,
          last_name: this.item.last_name,
          phone: pho,
          email: this.item.email,
          howKnow: this.item.howKnow,
          statusNom: this.item.statusNom ? this.item.statusNom.id : null,
          weight: this.item.weight,
          height: 0,
          birthDate: this.item.birthDate,
          isMale: this.item.isMale,
          procedureInterest: this.item.procedureInterest,
          children: this.item.children,
          medicalHistory:
            this.item.medicalHistory != null
              ? {
                  doctorChoiceUuid: null,
                  smoking: this.item.medicalHistory.smoking,
                  alcohol: this.item.medicalHistory.alcohol,
                  otherSubstances: this.item.medicalHistory.otherSubstances,
                  medicalCondition: this.item.medicalHistory.medicalCondition,
                  alergies: this.item.medicalHistory.alergies,
                  previosSurgery: this.item.medicalHistory.previosSurgery,
                  currentMedications:
                    this.item.medicalHistory.currentMedications,
                  details: this.item.medicalHistory.details,
                  bloodClotsOrClotting:
                    this.item.medicalHistory.bloodClotsOrClotting,
                  bestTimeToCall: this.item.medicalHistory.bestTimeToCall,
                  desiredSurgeryDate:
                    this.item.medicalHistory.desiredSurgeryDate,
                  buttShotsInjections:
                    this.item.medicalHistory.buttShotsInjections,
                }
              : {
                  doctorChoiceUuid: null,
                  smoking: false,
                  alcohol: false,
                  otherSubstances: null,
                  medicalCondition: null,
                  alergies: null,
                  previosSurgery: null,
                  currentMedications: null,
                  details: null,
                  bloodClotsOrClotting: null,
                  bestTimeToCall: null,
                  desiredSurgeryDate: null,
                  buttShotsInjections: false,
                },
        };
        if (this.item.medicalHistory && this.item.medicalHistory.doctorChoice) {
          this.itemEdit.medicalHistory.doctorChoiceUuid =
            this.item.medicalHistory.doctorChoice.uuid;
        }

        this.gender = this.itemEdit.isMale ? "male" : "female";
      }
    },

    loadLeadStatus() {
      getAPI(`/lead-status-nom/list`)
        .then((res) => {
          this.status = res.data.filter((f) => f.status != "NO_ACTIVE");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    resetForm() {
      this.formComposition = {
        sourceDefault: null,
        doctorChoise: {
          name: "doctorChoise",
          label: "Doctor of Choice",
          active: true,
        },
        procedureInterest: {
          name: "procedureInterest",
          label: "Procedure of Interest",
          active: true,
        },
        medicalCondition: {
          name: "medicalCondition",
          label: "Do you have any medical conditions?",
          active: true,
        },
        medications: {
          name: "medications",
          label: "Do you take any medications?",
          active: true,
        },
        alergies: {
          name: "alergies",
          label: "Alergies",
          active: true,
        },
        bloodClots: {
          name: "bloodClots",
          label: "Have you ever had any blood clots/ blood clotting?",
          label2: "If yes, please describe the situation and when it happened",
          active: true,
        },
        previousSurgery: {
          name: "previousSurgery",
          label: "Any procedures or surgeries in the last 3 months?",
          label2: "Please specify.",
          active: true,
        },
        buttshots: {
          name: "buttshots",
          label: "Do you have butt shots/ injections?",
          active: true,
        },
        children: {
          name: "children",
          label: "Do you have Children?",
          active: true,
        },
        smoke: {
          name: "smoke",
          label: "Do you smoke or use any nicotine products?",
          active: true,
        },
        alcohol: {
          name: "alcohol",
          label: "Do you drink alcohol?",
          active: true,
        },
        other: {
          name: "other",
          label: "Other Substances",
          label2: "Substances",
          active: true,
        },
        desireSurgeryDate: {
          name: "desireSurgeryDate",
          label: "Desired Surgery Date",
          active: true,
        },
        source: {
          name: "source",
          label: "Please tell us how you heard about us",
          active: true,
        },
        details: {
          name: "details",
          label: "Notes",
          active: true,
        },
        photo: {
          name: "photo",
          label: "Photos",
          label1: "Attach files",
          active: true,
        },
        besttime: {
          name: "besttime",
          label: "Best time to call",
          label1: "(office hours 9am- 6pm, M-F)",
          active: true,
        },
        emailme: {
          name: "emailme",
          label: "Email me a copy of my responses.",
          label1:
            "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
          active: true,
        },
      };
    },
    initForm() {
      if (this.configForm.length != 0) {
        if (this.defaultFormId) {
          const uuid = this.defaultFormId.value;
          const f = this.configForm.find((cf) => cf.uuid == uuid);
          if (f) {
            const temp = JSON.parse(f.value);
            this.formComposition = temp;
          } else {
            const temp = JSON.parse(this.configForm[0].value);
            this.formComposition = temp;
          }
        } else {
          const temp = JSON.parse(this.configForm[0].value);
          this.formComposition = temp;
        }
      } else {
        this.resetForm();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cardexpand {
  width: 100vh !important;
}
.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}
.texts1 {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}
.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}
.actions {
  margin-top: -30px !important;
}
.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}
.field {
  padding: 0 15px;
}
.heightfield {
  margin-top: -27px !important;
}
</style>
