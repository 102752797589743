


























































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import moment from "moment";

/**
 * @property {boolean} past Configure the date picker to allow date selection from current day to previous dates
 * @property {boolean} future Configure the date picker to allow date selection from current day to future dates
 * @property {string} label The input label
 */
export default Vue.extend({
  name: "ma-datepicker",
  props: {
    value: String,
    future: Boolean,
    past: Boolean,
    label: String,
    datepickerProps: Object,

    required: {
      type: Boolean,
      default: () => true,
    },
    disable: {
      type: Boolean,
      default: () => false,
    },

    editable: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      menu: false,
      mValue: "",
      rules,
      dialog: false,
      dobdate: "",
    };
  },
  computed: {
    configureRange(): { max?: string | Date; min?: string | Date } {
      let result = {};

      if (this.past) {
        result = {
          max: new Date().toISOString().substr(0, 10),
          min: "1950-01-01",
        };
      }

      if (this.future) {
        result = {
          min: new Date().toISOString(),
          max: "",
        };
      }

      return result;
    },
    formatDate(): string {
      if (
        this.mValue != "" &&
        this.mValue != null &&
        this.mValue != undefined
      ) {
        const [year, month, day] = this.mValue.split("-");
        if (this.editable) {
          return `${year}-${month}-${day}`;
        }
        return `${month}-${day}-${year}`;
      }

      return "";
    },
  },
  watch: {
    value(val: string) {
      if (this.editable) {
        this.dobdate = val;
      } else {
        this.mValue = val;
      }
    },

    formatDate: {
      handler() {
        const [year, month, day] = this.mValue.split("-");
      },
    },
    dobdate: {
      handler() {
        this.saveDate();
      },
    },
  },
  methods: {
    saveMenu(date: string): void {
      (this as any).$refs.menu.save(date);
      this.$emit("input", date);
    },
    saveDate() {
      if (this.dobdate != null) {
        const [year, month, day] = this.dobdate.split("-");
        if (year == undefined || month == undefined || day == undefined) {
          this.$emit("input", "");
        } else {
          this.$emit("input", this.dobdate);
        }
      }
    },
  },
});
